.letter-spacing-button {
    margin: 0;
    background-color: transparent;
    color: black;
    text-transform: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

.letter-spacing-button:hover {
    cursor: pointer;
}
