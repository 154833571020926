.letter-casing-button {
    margin: 0;
    background-color: transparent;
    color: black;
    text-transform: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    line-height: 24px;
    font-weight: bolder;
}

.letter-casing-button:hover {
    cursor: pointer;
}
